import Button from "../Button/Button";
import Input from "../Input/Input";
import "./PurchaseTeam.scss";
import axios from "axios";
import { useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCreditCard,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-regular-svg-icons";
import ReactLoading from "react-loading";
import Select from "react-select";
import AppStoreDownload from "../../assets/images/appstore.svg";
import GooglePlayDownload from "../../assets/images/google-play.svg";
import { toast } from "react-toastify";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import jwt from "jwt-decode";

const getExt = (filename = '') => {
  try {
    let ext = filename.split('.').pop();
    if(ext === filename) ext = "";
    return {
      path: filename.replace(`.${ext}`, ''),
      ext,
    };
  } catch (e) {

  }
}

const PurchaseTeam = ({
  bossName,
  bossImages,
  suspect1,
  suspect2,
  suspect3,
  suspect4,
  suspect5,
  setControlVisible,
}) => {
  const [auth, setAuth] = useState();
  const [accountExist, setAccountExist] = useState(false);

  const [isLoading, setIsloading] = useState(false);
  const [complete, setComplete] = useState(false);

  const [passwordVisible, setPasswordVisible] = useState(false);

  const options = [
    { value: "2", label: "2" },
    { value: "5", label: "5" },
    { value: "10", label: "10" },
    { value: "15", label: "15" },
    { value: "20", label: "20" },
  ];

  const [totalTeams, setTotalTeams] = useState(options[1]);

  const [loadingText, setLoadingText] = useState("Almost there");

  // Stripe
  const stripe = useStripe();
  const elements = useElements();

  const [stripeId, setStripeId] = useState();

  const [formData, setFormData] = useState({
    first_name: null,
    last_name: null,
    email: null,
    password: null,
    cpassword: null,
  });

  let [images, setImages] = useState([
    {
      file: suspect1.image && suspect1.image[0] && suspect1.image[0].file,
      url: null,
      data_url:
        suspect1.image && suspect1.image[0] && suspect1.image[0].data_url,
    },
    {
      file: suspect2.image && suspect2.image[0] && suspect2.image[0].file,
      url: null,
      data_url:
        suspect2.image && suspect2.image[0] && suspect2.image[0].data_url,
    },
    {
      file: suspect3.image && suspect3.image[0] && suspect3.image[0].file,
      url: null,
      data_url:
        suspect3.image && suspect3.image[0] && suspect3.image[0].data_url,
    },
    {
      file: suspect4.image && suspect4.image[0] && suspect4.image[0].file,
      url: null,
      data_url:
        suspect4.image && suspect4.image[0] && suspect4.image[0].data_url,
    },
    {
      file: suspect5.image && suspect5.image[0] && suspect5.image[0].file,
      url: null,
      data_url:
        suspect5.image && suspect5.image[0] && suspect5.image[0].data_url,
    },
    {
      file:
        bossImages.hostage &&
        bossImages.hostage[0] &&
        bossImages.hostage[0].file,
      url: null,
      data_url:
        bossImages.hostage &&
        bossImages.hostage[0] &&
        bossImages.hostage[0].data_url,
    },
    {
      file: bossImages.free && bossImages.free[0] && bossImages.free[0].file,
      url: null,
      data_url:
        bossImages.free && bossImages.free[0] && bossImages.free[0].data_url,
    },
  ]);

  useEffect(() => {
    setImages([
      {
        file: suspect1.image && suspect1.image[0].file,
        url: null,
        data_url:
          suspect1.image && suspect1.image[0] && suspect1.image[0].data_url,
      },
      {
        file: suspect2.image && suspect2.image[0] && suspect2.image[0].file,
        url: null,
        data_url:
          suspect2.image && suspect2.image[0] && suspect2.image[0].data_url,
      },
      {
        file: suspect3.image && suspect3.image[0] && suspect3.image[0].file,
        url: null,
        data_url:
          suspect3.image && suspect3.image[0] && suspect3.image[0].data_url,
      },
      {
        file: suspect4.image && suspect4.image[0] && suspect4.image[0].file,
        url: null,
        data_url:
          suspect4.image && suspect4.image[0] && suspect4.image[0].data_url,
      },
      {
        file: suspect5.image && suspect5.image[0] && suspect5.image[0].file,
        url: null,
        data_url:
          suspect5.image && suspect5.image[0] && suspect5.image[0].data_url,
      },
      {
        file:
          bossImages.hostage &&
          bossImages.hostage[0] &&
          bossImages.hostage[0].file,
        url: null,
        data_url:
          bossImages.hostage &&
          bossImages.hostage[0] &&
          bossImages.hostage[0].data_url,
      },
      {
        file: bossImages.free && bossImages.free[0].file,
        url: null,
        data_url:
          bossImages.free && bossImages.free[0] && bossImages.free[0].data_url,
      },
    ]);
  }, [bossName, bossImages, suspect1, suspect2, suspect3, suspect4, suspect5]);

  let templateStrings = [
    {
      string: "suspectone",
      value: suspect1.name,
    },
    {
      string: "suspecttwo",
      value: suspect2.name,
    },
    {
      string: "suspectthree",
      value: suspect3.name,
    },
    {
      string: "suspectfour",
      value: suspect4.name,
    },
    {
      string: "suspectfive",
      value: suspect5.name,
    },
    {
      string: "bossname",
      value: bossName.name,
    },
    {
      string: "sonetone",
      value: suspect1.truth_1,
    },
    {
      string: "sonettwo",
      value: suspect1.truth_2,
    },
    {
      string: "sonelone",
      value: suspect1.lie,
    },
    {
      string: "stwotone",
      value: suspect2.truth_1,
    },
    {
      string: "stwottwo",
      value: suspect2.truth_2,
    },
    {
      string: "stwoltwo",
      value: suspect2.lie,
    },
    {
      string: "sthreetone",
      value: suspect3.truth_1,
    },
    {
      string: "sthreettwo",
      value: suspect3.truth_2,
    },
    {
      string: "sthreelone",
      value: suspect3.lie,
    },
    {
      string: "sfourtone",
      value: suspect4.truth_1,
    },
    {
      string: "sfourttwo",
      value: suspect4.truth_2,
    },
    {
      string: "sfourlone",
      value: suspect4.lie,
    },
    {
      string: "sfivetone",
      value: suspect5.truth_1,
    },
    {
      string: "sfivettwo",
      value: suspect5.truth_2,
    },
    {
      string: "sfivelone",
      value: suspect5.lie,
    },
  ];

  let [gameObject, setGameObject] = useState(null);

  useEffect(() => {
    const authenticate = () => {
      if (accountExist) {
        return signToUserAccount();
      } else {
        setLoadingText("Creating you an account");
        return createAccount();
      }
    }
    if (gameObject) {
      authenticate()
        .then((res) => {
          
          return transferOwnership(gameObject, res);
        })
        .then(() => {
          
          setLoadingText("Don't forget to check your email");
          sendEmail(gameObject);
        })
        .then(() => {
          setComplete(true);
          deleteLocalStorage();
        })
        .catch((err) => console.error(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameObject]);

  useEffect(() => {
    if (stripeId) {
      createGame(stripeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const dataUrlToFile = async (dataUrl, fileName) => {
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], fileName, { type: "image/jpeg" });
  };

  const uploadImages = async (game) => {
    const taskImages = []
    const promises = images.map((image, i) => {
      const imageName = (Math.random() + 1).toString(36).substring(7) + ".jpg";
      let imageFile;

      return dataUrlToFile(image.data_url, imageName)
        .then((file) => {
          imageFile = file;
          return axios.get(
            `https://app.socialscavenger.com/v1/medias/sign/${
              file.name.split(".")[1]
            }?user_id=${
              process.env.REACT_APP_ADMIN_USERNAME
            }&user_type=email&password=${process.env.REACT_APP_ADMIN_PASSWORD}`,
            {
              headers: {
                "X-Whitelabel": "ss",
              },
            }
          );
        })
        .then(async (res) => {
          await axios.put(res.data.signature, imageFile, {
            headers: {
              "Content-Type": imageFile.type,
            },
          });
          return res
        })
        .then((res) => {
          const { name, uri } = res?.data || {}
          taskImages.push({
            name,
            uri,
          })
          return true
        })
        .catch((err) => console.log(err));
    });
    await Promise.all(promises);
    return { game, taskImages };
  };

  const updateImages = async ({ game, taskImages }) => {
    const { tasks = [] } = game || {}
    const byMissionBriefing = t => t.name === 'Mission Briefing' || t.unlock_by_task_txt === 'Mission Briefing'
    const relevantTasks = tasks.filter(byMissionBriefing).map(t => ({
      id: t.id,
      name: t.name,
      task_type: t.task_type,
      assets: t.assets,
      description: t.description,
    }))
    const startTask = relevantTasks.shift()
    const endTask = relevantTasks.pop()
    const promises = taskImages.map((taskImage, index) => {
      const { name, uri } = taskImage
      const imgStr = getExt(name)
      const startImgStr = getExt(taskImages[5]?.name)
      let postValues;
      let taskId;

      switch(true) {
        // Suspect tasks
        case index < 5:
          postValues = {
            game_id: game.id,
            photo_media_uuid: imgStr.path,
            photo_media_extension: imgStr.ext,
          }
          taskId = relevantTasks[index]?.id
          break;
        
        // Mission Briefing task
        case index === 5:
          postValues = {
            game_id: game.id,
            description: (startTask?.description || '').replace(/<img[^>]+src="([^">]+)"/g, `<img src="${uri}"`),
          }
          taskId = startTask?.id
          break;

        // Case closed task
        case index === 6:
          postValues = {
            game_id: game.id,
            correct_media_uuid: imgStr.path,
            correct_media_extension: imgStr.ext,
            incorrect_media_uuid: startImgStr.path,
            incorrect_media_extension: startImgStr.ext,
          }
          taskId = endTask?.id
          break;
        default:
          break;
      }

      if (postValues && taskId) {
        return axios
          .put(
            `https://app.socialscavenger.com/v1/tasks/${taskId}.json`,
            postValues,
            {
              headers: {
                "Content-Type": "application/json",
                "X-Whitelabel": "ss",
                Authorization: "Bearer " + auth,
              },
            }
          )
          .then()
          .catch((err) => console.log(err));
      }

      return false;
    });

    await Promise.all(promises);
    return game;
  };

  const addPersonalization = async (game) => {
    const promises = templateStrings.map((template) => {
      return axios
        .post(
          `https://app.socialscavenger.com/v1/personalizations.json`,
          {
            game_id: game.id,
            key: template.string,
            value: template.value,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "X-Whitelabel": "ss",
              Authorization: "Bearer " + auth,
            },
          }
        )
        .then()
        .catch((err) => console.log(err));
    });
    await Promise.all(promises);
    return game;
  };

  const updateGameName = async (game) => {
    const promise = axios
      .put(
        `https://app.socialscavenger.com/v1/games/${game.id}.json`,
        {
          name: "Who Kidnapped %{bossname}?! - Custom (90 min version)",
        },
        {
          headers: {
            "X-Whitelabel": "ss",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth,
          },
        }
      )
      .then()
      .catch((err) => console.log(err));
    await promise;
    return game;
  };

  const createAccount = async () => {
    let playerData;

    const promise = axios
      .post(
        "https://app.socialscavenger.com/v1/users.json",
        {
          email: formData.email,
          password: formData.password,
          password_confirmation: formData.cpassword,
          name: formData.first_name + " " + formData.last_name,
        },
        {
          headers: {
            "X-Whitelabel": "ss",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => (playerData = res.data))
      .catch((err) => {
        switch (err.response.status) {
          case 409:
            toast.error("Email already exist");
            break;
          case 422:
            toast.error("We are having some issues in our end!");
            break;
          default:
            break;
        }
      });

    await promise;
    return playerData;
  };

  const signToUserAccount = async () => {
    let playerData;

    const promise = axios
      .post(
        "https://app.socialscavenger.com/v1/users/authenticate.json",
        {
          email: formData.email,
          password: formData.password,
        },
        {
          headers: {
            "X-Whitelabel": "ss",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => (playerData = jwt(res.data.token)))
      .catch((err) => {
        switch (err.response.status) {
          case 401:
            break;
          default:
            break;
        }
      });

    await promise;
    return playerData;
  };

  const signIn = async () => {
    const promise = axios
      .post(
        "https://app.socialscavenger.com/v1/users/authenticate.json",
        {
          email: process.env.REACT_APP_ADMIN_USERNAME,
          password: process.env.REACT_APP_ADMIN_PASSWORD,
        },
        {
          headers: {
            "X-Whitelabel": "ss",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setAuth(res.data.token);
      })
      .catch((err) =>
        toast.error("Something went wrong! Please contact support.")
      );

    await promise;
    return true;
  };

  const transferOwnership = async (game, player) => {
    const promise = axios
      .post(
        `https://app.socialscavenger.com/v1/games/${game.id}/administrator.json1`,
        {
          player_id: accountExist ? player.user_id : player.id,
        },
        {
          headers: {
            "X-Whitelabel": "ss",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth,
          },
        }
      )
      // .then(() => {
      //     return axios
      //     .delete(`https://app.socialscavenger.com/v1/games/${game.id}/administrator.json`, {
      //         player_id: process.env.REACT_APP_ADMIN_ID
      //     }, {
      //         headers: {
      //             "X-Whitelabel": "ss",
      //             "Content-Type": "application/json",
      //             Authorization: "Bearer " + auth,
      //         }
      //     })
      // })
      .catch((err) => console.log(err));

    await promise;
    return true;
  };

  const makePayment = async (stripe_id, game) => {
    const promise = axios
      .patch(
        `https://app.socialscavenger.com/v1/games/${game.data.id}/teams.json`,
        {
          cost: totalTeams.value * 10,
          stripe_id: stripe_id,
          purchased_players: totalTeams.value,
        },
        {
          headers: {
            "X-Whitelabel": "ss",
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth,
          },
        }
      )
      .catch((err) => console.log(err));
    await promise;
    return game;
  };

  const sendEmail = async (gameData) => {
    const promise = axios
      .post(
        `https://app.socialscavenger.com/v1/services/email/player.json`,
        {
          name: formData.first_name + " " + formData.last_name,
          email: formData.email,
          subject: "Your Game Is Ready!!",
          txt: `Your game is ready, here is your game unlock code: ${gameData.code}`,
          html: `<section class="thank-you">
            <div class="thank-you__wrapper">
                <span class="thank-you__check"
                    ><svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="far"
                    data-icon="circle-check"
                    class="svg-inline--fa fa-circle-check"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    >
                    <path
                        fill="currentColor"
                        d="M243.8 339.8C232.9 350.7 215.1 350.7 204.2 339.8L140.2 275.8C129.3 264.9 129.3 247.1 140.2 236.2C151.1 225.3 168.9 225.3 179.8 236.2L224 280.4L332.2 172.2C343.1 161.3 360.9 161.3 371.8 172.2C382.7 183.1 382.7 200.9 371.8 211.8L243.8 339.8zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"
                    ></path></svg>
                    </span>
                <div class="thank-you__reminder">
                    <h1 class="thank-you__title">Thank you for your purchase!</h1>
                    <p class="thank-you__description">
                    Here is your reciept of your purchase with Social Scavenger
                    </p>
                </div>
                <div class="thank-you__reciept">
                    <div class="thank-you__reciept-header">
                    <p class="thank-you__reciept-header-title">Who Kidnapped The Boss</p>
                    <p class="thank-you__reciept-header-price">$${
                      totalTeams.value
                    } x 10</p>
                    </div>
                    <div class="thank-you__reciept__total">
                    <p>Total:</p>
                    <p class="thank-you__reciept__total-price">$${
                      totalTeams.value * 10
                    }</p>
                    </div>
                </div>
                <div class="thank-you__game">
                    <div class="thank-you__game-data">
                    <h2 class="thank-you__game-code">Access Code: ${
                      gameData.code
                    }</h2>
                    <div class="thank-you__game-link">
                        <p class="thank-you__game-link-text">
                        https://play.socialscavenger.com/#/signin?code=${
                          gameData.code
                        }
                        </p>
                    </div>
                    <p class="thank-you__game-link-info">Your quick start link</p>
                    </div>
                    <div><button class="button button--primary">Start Playing</button></div>
                </div>
                <p className="thank-you__timer-warning">Your timer starts as soon as you sign in!</p>
                <div className="thank-you__downloads">
                    <a href="https://apps.apple.com/ca/app/social-scavenger/id547037138"><img className="thank-you__downloads-image" src="https://svgshare.com/i/hxP.svg" alt="download-on-appstore"/></a>
                    <a href="https://play.google.com/store/apps/details?id=com.socialscavenger.ss&hl=en_CA&gl=US"><img className="thank-you__downloads-image" src="https://svgshare.com/i/hy4.svg" alt="download-on-google-play"/></a>
                </div>
                <div class="thank-you__footer">
                    <p class="thank-you__footer-contact">
                    If you have any question of concerns, please <a href="#232">Contact Us</a>
                    </p>
                    <p class="thank-you__footer-copyright">
                    Copyright © 2022 Social Scavenger| All Rights Reserved
                    </p>
                </div>
            </div>
        </section>
        
        <style>
            /* =================== Colors */
        /* =================== Others */
        /* =================== Reusable Components */
        /* latin-ext */
        @font-face {
          font-family: 'Audiowide';
          font-style: normal;
          font-weight: 400;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/audiowide/v14/l7gdbjpo0cum0ckerWCdmA_OIxo.woff2) format('woff2');
          unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }
        /* latin */
        @font-face {
          font-family: 'Audiowide';
          font-style: normal;
          font-weight: 400;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/audiowide/v14/l7gdbjpo0cum0ckerWCdlg_O.woff2) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        /* devanagari */
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 300;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/poppins/v19/pxiByp8kv8JHgFVrLDz8Z11lFc-K.woff2) format('woff2');
          unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
        }
        /* latin-ext */
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 300;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/poppins/v19/pxiByp8kv8JHgFVrLDz8Z1JlFc-K.woff2) format('woff2');
          unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }
        /* latin */
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 300;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/poppins/v19/pxiByp8kv8JHgFVrLDz8Z1xlFQ.woff2) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        /* devanagari */
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/poppins/v19/pxiEyp8kv8JHgFVrJJbecmNE.woff2) format('woff2');
          unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
        }
        /* latin-ext */
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/poppins/v19/pxiEyp8kv8JHgFVrJJnecmNE.woff2) format('woff2');
          unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }
        /* latin */
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/poppins/v19/pxiEyp8kv8JHgFVrJJfecg.woff2) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        /* devanagari */
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/poppins/v19/pxiByp8kv8JHgFVrLEj6Z11lFc-K.woff2) format('woff2');
          unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
        }
        /* latin-ext */
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/poppins/v19/pxiByp8kv8JHgFVrLEj6Z1JlFc-K.woff2) format('woff2');
          unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }
        /* latin */
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/poppins/v19/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        /* devanagari */
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 700;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/poppins/v19/pxiByp8kv8JHgFVrLCz7Z11lFc-K.woff2) format('woff2');
          unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
        }
        /* latin-ext */
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 700;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/poppins/v19/pxiByp8kv8JHgFVrLCz7Z1JlFc-K.woff2) format('woff2');
          unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
        }
        /* latin */
        @font-face {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 700;
          font-display: swap;
          src: url(https://fonts.gstatic.com/s/poppins/v19/pxiByp8kv8JHgFVrLCz7Z1xlFQ.woff2) format('woff2');
          unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
        }
        
        * {
            box-sizing: border-box;
        }
        
        .button {
             border: none;
             border-radius: 5px;
             padding: 13.33px 48px;
             color: #f8f8f8;
        }
         .button--primary {
             background-color: #725095;
        }
         .button--secondary {
             background-color: #3a2d47;
        }
         .button:hover {
             cursor: pointer;
        }
        
        body {
            margin: 0;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-family: 'Audiowide', cursive;
            font-family: 'Poppins', sans-serif;
            background: white;
        }
        
        h1 {
            font-size: 32px;
        }
        
        h2 {
            font-size: 24px;
        }
        
        h3 {
            font-size: 18px;
        }
        
        .thank-you {
             width: 100%;
             height: 100%;
             display: flex;
             justify-content: center;
             align-items: center;
             background: white;
        }
         .thank-you__wrapper {
             width: 100%;
             padding: 40px;
             display: flex;
             flex-direction: column;
             gap: 32px;
             align-items: center;
             justify-content: center;
             background: white;
        }
         @media (min-width: 768px) {
             .thank-you__wrapper {
                 padding: 32px;
            }
        }
         @media (min-width: 1000px) {
             .thank-you__wrapper {
                 width: 1000px !important;
                 margin: 0 auto;
            }
        }
         .thank-you__check {
             font-size: 40px;
             color: #8ed98e;
             margin: 0;
        }
         .thank-you__devider {
             border: 0;
             clear: both;
             display: block;
             width: 30%;
             background-color: #bbb;
             height: 1px;
        }
         .thank-you__reminder {
             display: flex;
             flex-direction: column;
             align-items: center;
             justify-content: center;
             gap: 32px;
        }
         .thank-you__title {
             margin: 0;
        }
         .thank-you__description {
             margin: 0;
             color: #bbb;
        }
         .thank-you__reciept {
             display: flex;
             flex-direction: column;
             gap: 16px;
        }
         .thank-you__reciept-header {
             display: flex;
             justify-content: space-between;
             width: 800px;
             padding: 16px 32px;
             box-shadow: 0px 0px 10px 0px #e7e7e7;
             border-radius: 5px;
        }
         .thank-you__reciept-header-title {
             font-weight: 600;
        }
         .thank-you__reciept-header-price {
             color: #7dbe7d;
             font-weight: 600;
        }
         .thank-you__reciept__total {
             display: flex;
             justify-content: flex-end;
             gap: 64px;
             padding: 0 32px;
        }
         .thank-you__reciept__total-price {
             font-weight: bold;
        }
         .thank-you__game {
             width: 800px;
             display: flex;
             justify-content: space-between;
             align-items: center;
        }
         .thank-you__game-data {
             display: flex;
             flex-direction: column;
             gap: 16px;
        }
         .thank-you__game-code {
             margin: 0;
        }
         .thank-you__game-link {
             padding: 8px 16px;
             border: 2px dashed #a7a3ff;
             border-radius: 5px;
             cursor: pointer;
        }
         .thank-you__game-link-text {
             margin: 0;
        }
         .thank-you__game-link-info {
             margin: 0;
             font-size: 13px;
        }
        .thank-you__timer-warning {
            margin-top: 50px;
            color: rgb(250, 129, 129);
            font-weight: bold;
        }
        .thank-you__downloads {
            display: flex;
            gap: 16px;
    
            &-image {
                height: 35px;
            }
        }
        .thank-you__footer {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 4px;
        }
        .thank-you__footer-contact {
            margin: 0;
        }
        .thank-you__footer-copyright {
            margin: 0;
            font-size: 13px;
        }
        </style>`,
        },
        {
          headers: {
            "X-Whitelabel": "ss",
            "Content-Type": "application/json",
          },
        }
      )
      .then()
      .catch((err) => console.log(err));
    await promise;
    return gameData;
  };

  const deleteLocalStorage = () => {
    localStorage.removeItem("suspect1");
    localStorage.removeItem("suspect2");
    localStorage.removeItem("suspect3");
    localStorage.removeItem("suspect4");
    localStorage.removeItem("suspect5");
    localStorage.removeItem("bossName");
    localStorage.removeItem("bossImages");
    localStorage.removeItem("current-page");
  };

  const checkAccountExist = (email) => {
    axios
      .get(
        `https://app.socialscavenger.com/v1/users/verify.json?email=${email}`,
        {
          headers: {
            "X-Whitelabel": "ss",
          },
        }
      )
      .then((res) => {
        setAccountExist(true);
      })
      .catch((err) => {
        setAccountExist(false);
      });
  };

  const createGame = (stripe_id) => {
    
    setLoadingText("Getting things ready");
    axios
      .post(
        `https://app.socialscavenger.com/v1/games/7850/clone.json`,
        {},
        {
          // Cloning Game
          headers: {
            "X-Whitelabel": "ss",
            Authorization: "Bearer " + auth,
          },
        }
      )
      .then((res) => {
        
        setLoadingText("Handling your payment");
        return makePayment(stripe_id, res);
      })
      .then((res) => {
        
        setLoadingText("Setting up your friends");
        return axios.get(
          `https://app.socialscavenger.com/v1/tasks.json?code=${res.data.code}`,
          {
            // Getting tasks
            headers: {
              "X-Whitelabel": "ss",
              Authorization: "Bearer " + auth,
            },
          }
        );
      })
      .then((res) => {
        
        setLoadingText("Uploading your images");
        return uploadImages(res.data); // Uploading images + Passing data to this function to pass it to next one
      })
      .then((res) => {
        return updateImages(res)
      })
      .then((res) => {
        
        setLoadingText("Personalizing your game");
        return addPersonalization(res);
      })
      .then((res) => {
        
        return updateGameName(res);
      })
      .then((res) => {
        setGameObject(res);
      })
      .catch((err) => console.error(err));
  };

  const handlePaymentSubmit = async (event) => {
    event.preventDefault();

    if (Object.values(formData).some((data) => data === null || data === "")) {
      return toast.warn("Make sure all fields are filled!");
    }

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      return toast.warn(error.message);
    }

    setIsloading(true);
    setControlVisible(false);

    const { id } = paymentMethod;
    setStripeId(id);

    
    signIn();

    // axios
    //   .get(
    //     `https://app.socialscavenger.com/v1/users/verify.json?email=${formData.email}`,
    //     {
    //       headers: {
    //         "X-Whitelabel": "ss",
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     setAccountExist(true);
    //     toast.warn("That email already exist in our records!");
    //   })
    //   .catch((err) => {
    //     if (err.response.status === 404) {
    //       setIsloading(true);
    //       setControlVisible(false);

    //       const { id } = paymentMethod;
    //       setStripeId(id);

    //       
    //       signIn();
    //     }
    //   });
  };

  if (complete) {
    return (
      <section className="thank-you">
        <span className="thank-you__check">
          <FontAwesomeIcon icon={faCheckCircle} />
        </span>
        <div className="thank-you__reminder">
          <h2 className="thank-you__title">Enjoy your experience.</h2>
          <p className="thank-you__description">
            You will recivie an email reciept with confirmation
          </p>
        </div>
        <div className="thank-you__reciept">
          <div className="thank-you__reciept-header">
            <p className="thank-you__reciept-header-title">
              Who Kidnapped The Boss Event Experience
            </p>
            <p className="thank-you__reciept-header-price">
              $10 USD x {totalTeams.value}
            </p>
          </div>
          <div className="thank-you__reciept__total">
            <p>Total: </p>
            <p className="thank-you__reciept__total-price">
              ${totalTeams.value * 10} USD
            </p>
          </div>
        </div>
        <div className="thank-you__game">
          <div className="thank-you__game-data">
            <h3 className="thank-you__game-code">
              Access Code:{" "}
              <span className="thank-you__game-code-id">
                {gameObject && gameObject.code}
              </span>
            </h3>
            <div className="thank-you__game-link">
              <CopyToClipboard
                text={`https://play.socialscavenger.com/#/signin?code=${
                  gameObject && gameObject.code
                }`}
                onCopy={() =>
                  toast.info("Game quick link copied to clipboard!")
                }
              >
                <p className="thank-you__game-link-text">
                  https://play.socialscavenger.com/#/signin?code=
                  {gameObject && gameObject.code}
                </p>
              </CopyToClipboard>
            </div>
            <p className="thank-you__game-link-info">
              Share this quick start link to allow people to join easily.
            </p>
          </div>
          <div>
            <Button
              text="Start Playing"
              type="primary"
              fn={() =>
                window.open(
                  `https://play.socialscavenger.com/#/signin?code=${
                    gameObject && gameObject.code
                  }`
                )
              }
              icon={<FontAwesomeIcon icon={faPlay} />}
            />
          </div>
        </div>
        <p className="thank-you__timer-warning">
          Your event timer starts as soon as you sign in!
        </p>
        <div className="thank-you__downloads">
          <a
            href="https://apps.apple.com/ca/app/social-scavenger/id547037138"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="thank-you__downloads-image"
              src={AppStoreDownload}
              alt="download-on-appstore"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.socialscavenger.ss&hl=en_CA&gl=US"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="thank-you__downloads-image"
              src={GooglePlayDownload}
              alt="download-on-google-play"
            />
          </a>
        </div>
        <div className="thank-you__footer">
          <p className="thank-you__footer-contact">
            If you have any question of concerns, please{" "}
            <a href="#232">contact us</a>
          </p>
          <p className="thank-you__footer-copyright">
            Copyright © 2022 Social Scavenger| All Rights Reserved
          </p>
        </div>
      </section>
    );
  } else {
    return (
      <>
        <div className="purchase-team">
          <div
            className="purchase-team__loading"
            style={{ display: isLoading ? "flex" : "none" }}
          >
            <ReactLoading
              className="purchase-team__loader"
              type={"cylon"}
              color={"#1DBBDF"}
              height={"10%"}
              width={"10%"}
            />
            <p className="purchase-team__loading-helper">{loadingText}...</p>
            <div className="purchase-team__loading-info">
              <p className="purchase-team__loading-text">
                Please wait while we process your request.
              </p>
              <p className="purchase-team__loading-help">
                Do not close this window! This shouldn't take more than{" "}
                <b>a minute</b> :)
              </p>
            </div>
          </div>
          <div
            className="purchase-team__container"
            style={{ display: isLoading ? "none" : "flex" }}
          >
            <div className="purchase-team__intro">
              <h2 className="purchase-team__intro-title">Almost There!</h2>
              <p className="purchase-team__intro-description">
                You are almost done creating your perfect game. Once paid,
                you're ready to go.
              </p>
            </div>
            <div className="purchase-team__form">
              <div className="purchase-team__form-personal">
                <p>Account and billing details</p>
                <div className="purchase-team__form-inline">
                  <Input
                    placeholder="First Name"
                    value={formData.first_name}
                    onChange={(e) =>
                      setFormData({ ...formData, first_name: e.target.value })
                    }
                  />
                  <Input
                    placeholder="Last Name"
                    value={formData.last_name}
                    onChange={(e) =>
                      setFormData({ ...formData, last_name: e.target.value })
                    }
                  />
                </div>
                <Input
                  placeholder="Email"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  onBlur={(e) => checkAccountExist(e.target.value)}
                />
                <div
                  className="purchase-team__form-inline"
                  style={{ display: formData.email ? "flex" : "none" }}
                >
                  <Input
                    placeholder="Password"
                    type={passwordVisible ? "text" : "password"}
                    value={formData.password}
                    onChange={(e) =>
                      setFormData({ ...formData, password: e.target.value })
                    }
                    onBlur={(e) => {
                      if (accountExist) {
                        setFormData({
                          ...formData,
                          cpassword: e.target.value,
                        });
                      }
                    }}
                  />
                  <Input
                    placeholder="Confirm Password"
                    type={passwordVisible ? "text" : "password"}
                    value={formData.cpassword}
                    style2={{ display: accountExist ? "none" : "flex" }}
                    onChange={(e) =>
                      setFormData({ ...formData, cpassword: e.target.value })
                    }
                  />
                </div>
                <span
                  className="purchase-team__show-password"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  <FontAwesomeIcon
                    icon={passwordVisible ? faEye : faEyeSlash}
                  />{" "}
                  {passwordVisible ? "Hide Password" : "Show Password"}
                </span>
                <p className="purchase-team__form-reminder">
                  Your email and password will be used to sign in to Social
                  Scavenger apps.
                </p>
              </div>
              <div className="purchase-team__team">
                <p>Teams</p>
                <div className="purchase-team__team-selector">
                  <Select
                    className="purchase-team__team-select"
                    options={options}
                    value={totalTeams}
                    onChange={(e) => setTotalTeams(e)}
                  />
                  <span>
                    Total:{" "}
                    <b style={{ color: "#7dbe7d" }}>
                      ${totalTeams.value * 10} USD
                    </b>
                  </span>
                </div>
              </div>
              <div className="purchase-team__form-payment">
                <p>Secure Payment</p>
                <form
                  className="purchase-team__form-inline"
                  onSubmit={handlePaymentSubmit}
                >
                  <CardElement />
                  <Button
                    text="Purchase Event"
                    type="primary"
                    button_type="submit"
                    disabled={!stripe}
                    icon={<FontAwesomeIcon icon={faCreditCard} />}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default PurchaseTeam;
