import "./SuspectImage.scss";
import ImageUploading from "react-images-uploading";
import Resizer from "react-image-file-resizer";
import PinImage from "../../../assets/images/pin.png";
import Logo from "../../../assets/logos/logo.png";
import Cropper from "react-easy-crop";
import { useCallback, useEffect, useState } from "react";
import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import Modal from "react-modal";
import Button from "../../../components/Button/Button";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import getCroppedImg, { blobToBase64 } from "./cropImage";

const SuspectImage = ({ suspectName, state, setSuspect, number }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [modalIsOpen, setIsOpen] = useState(false);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "600px",
      height: "600px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "16px",
    },
  };

  const doit = () => {
    const node = document.getElementById("xddddd");
    console.log(node);

    htmlToImage
      .toPng(node)
      .then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        document.body.appendChild(img);
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  };

  const resizeFile = (file) => {
    try {
      Resizer.imageFileResizer(
        file[0].file,
        500,
        500,
        "JPEG",
        50,
        0,
        (uri) => {
          const imgData = file;
          imgData[0].data_url = uri;
          setSuspect({ ...state, image: imgData });
          setIsOpen(true);
        },
        "base64",
        500,
        500
      );
    } catch (err) {
      console.error(err);
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels);
    showCroppedImage(croppedAreaPixels, state.image && state.image[0].data_url);
  }, []);

  const showCroppedImage = useCallback(
    async (croppedAreaPixels, image) => {
      try {
        const croppedImage = await getCroppedImg(image, croppedAreaPixels, 0);
        fetch(croppedImage)
          .then((res) => {
            return res.blob();
          })
          .then((res) => {
            return blobToBase64(res);
          })
          .then((res) => {
            const imgData = state.image;
            imgData[0].data_url = res;
            setSuspect({ ...state, image: imgData });
          });
      } catch (e) {
        console.error(e);
      }
    },
    [state.image]
  );

  return (
    <span className="intro-form__input" id="xddddd">
      <div className="intro-form__header">
        <div
          className="intro-form__logo-container"
          style={{ marginBottom: "20px" }}
        >
          <img className="intro-form__logo" src={Logo} alt="Logo" />
        </div>
      </div>
      <h2 className="suspect__intro-title">Upload {suspectName}'s image</h2>
      <div className="example">
        <div className="example__body">
          <span className="example__type example__type--2">Photo Guide</span>
          <ul>
            <li>Upload highest quality image</li>
            <li>Make sure the image isn't streched</li>
            <li>Preffered image size is 1:1</li>
          </ul>
        </div>
      </div>
      <div className="suspect-image">
        <div
          className="example__image-container suspect-image-data"
          id={`suspect_image-${number}`}
        >
          <div className="example__image-container-photo" onClick={doit}>
            <span
              className={`example__image-container-photo-1 example__image-container-photo-1--type-${number}`}
            ></span>
            <span
              className={`example__image-container-photo-2 example__image-container-photo-2--type-${number}`}
            >
              <img
                className="example__image"
                src={state.image && state.image[0].data_url}
                alt="suspicious-pic"
              />
              <span
                className={`example__image-container-pin-container example__image-container-pin-container--type-${number}`}
              >
                <img
                  className="example__image-container-pin"
                  src={PinImage}
                  alt="pin"
                />
              </span>
              <div
                className={`example__image-container-tape example__image-container-tape--type-${number}`}
              >
                {state.name}
              </div>
            </span>
          </div>
        </div>
        <ImageUploading
          multiple={false}
          value={state.image}
          onChange={(e) => {
            resizeFile(e);
          }}
          maxNumber={3423}
          dataURLKey="data_url"
        >
          {({ onImageUpload, isDragging, dragProps }) => (
            <div
              className="suspect-component__uploader suspect-image-uploader"
              style={
                isDragging
                  ? { color: "#21DE93", borderColor: "#21DE93" }
                  : undefined
              }
              onClick={onImageUpload}
              {...dragProps}
            >
              <p className="suspect-component__uploader-title">
                Add new photo of {state.name ? state.name : "suspect"} or tap to
                upload
              </p>
            </div>
          )}
        </ImageUploading>
      </div>
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="image-crop-modal">
          <Cropper
            image={state.image && state.image[0].data_url}
            crop={crop}
            zoom={zoom}
            aspect={4 / 3}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
            style={{
              containerStyle: {
                width: "100%",
                height: "100%",
              },
            }}
          />
        </div>
        <div>
          <Button
            text="Crop"
            type="primary"
            fn={() => setIsOpen(false)}
            icon={<FontAwesomeIcon icon={faFloppyDisk} />}
          />
        </div>
      </Modal>
    </span>
  );
};

export default SuspectImage;
