import "./ThankYou.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import Button from "../../components/Button/Button";

const ThankYou = () => {
    return (
        <section className="thank-you">
            <div className="thank-you__wrapper">
                <span className="thank-you__check"><FontAwesomeIcon icon={faCheckCircle} /></span>
                <div className="thank-you__reminder">
                    <h1 className="thank-you__title">Thank you for your purchase!</h1>
                    <p className="thank-you__description">You will recivie an email with confirmation soon.</p>
                </div>
                <div className="thank-you__reciept">
                    <div className="thank-you__reciept-header">
                        <p className="thank-you__reciept-header-title">Who Kidnapped The Boss</p>
                        <p className="thank-you__reciept-header-price">$84 x 4</p>
                    </div>
                    <div className="thank-you__reciept__total">
                        <p>Total: </p>
                        <p className="thank-you__reciept__total-price">$343</p>
                    </div>
                </div>
                <hr className="thank-you__devider"/>
                <div className="thank-you__game">
                    <div className="thank-you__game-data">
                        <h2 className="thank-you__game-code">Access Code: 23j4h3</h2>
                        <div className="thank-you__game-link">
                            <p className="thank-you__game-link-text">https://play.socialscavenger.com/#/signin?code=23j4h3</p>
                        </div>
                        <p className="thank-you__game-link-info">Your quick start link</p>
                    </div>
                    <div>
                        <Button text="Start Playing" type="primary" />
                    </div>
                </div>
                <div className="thank-you__footer">

                </div>
            </div>
        </section>
    );
}

export default ThankYou;