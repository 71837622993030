import './style/partials/_global.scss';
import Welcome from './pages/Welcome/Welcome';
import {
	BrowserRouter,
	Routes,
	Route,
  } from "react-router-dom";
import NewGame from './pages/NewGame/NewGame';
import ThankYou from './pages/ThankYou/ThankYou';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
					<Routes>
            <Route path="/create" element={<NewGame />} />
            <Route path="/thankyou" element={<ThankYou />} />
						<Route path="/" element={<Welcome />} />
					</Routes>
				</BrowserRouter>
    </div>
  );
}

export default App;

/// Bugs

/* 
1. Description of templates not changing
2. Rewards not showing rightaway, or even update sometime

*/