import Logo from "../../../assets/logos/logo.png";
import "./SuspectMotive.scss";

const SuspectMotive = ({ suspectName, state, setSuspect }) => {
  return (
    <>
      <span className="intro-form__input">
        <div className="intro-form__header">
          <div
            className="intro-form__logo-container"
            style={{ marginBottom: "20px" }}
          >
            <img className="intro-form__logo" src={Logo} alt="Logo" />
          </div>
        </div>
        <h2 className="suspect__intro-title">Enter {suspectName}'s motive</h2>
        <div className="example">
          <div className="example__body">
            <span className="example__type">Example</span>
            <ul>
              <li>
                Last seen shopping for all black clothing... to disguise himself
                parhaps?
              </li>
              <li>
                Last seen buying rope and duct tape from Home Depot... she said
                she's doing a DIY project at home, but could that be a lie?
              </li>
            </ul>
          </div>
        </div>
        <div className="text-area-container">
          <textarea
            className="suspect-component__motive"
            rows="8"
            cols="50"
            maxLength={200}
            placeholder="Last seen shopping for all black clothing... to disguise himself parhaps?"
            value={state.motive}
            onChange={(e) => setSuspect({ ...state, motive: e.target.value })}
          />
          <span
            className="text-area-container__clear"
            onClick={() => setSuspect({ ...state, motive: "" })}
          >
            Clear
          </span>
        </div>
      </span>
    </>
  );
};

export default SuspectMotive;
