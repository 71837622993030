import FormSteps from "../../components/FormSteps/FormSteps";
import "./NewGame.scss";

const NewGame = () => {
    return (
        <section className="new-game">
            <div className="new-game__wrapper">
                <div>
                    <FormSteps />
                </div>
            </div>
        </section>
    );
}

export default NewGame;