import "./IntroForm.scss";
import Input from "../Input/Input";
import Logo from "../../assets/logos/logo.png";

const IntroForm = ({ bossName, setBossName }) => {
  return (
    <section className="intro-form">
      <div className="intro-form__header">
        <div className="intro-form__logo-container">
          <img className="intro-form__logo" src={Logo} alt="Logo" />
        </div>
        <div className="intro-form__intro">
          <p className="intro-form__intro-description">
            You will be guided one step at a time to create your custom game.
          </p>
        </div>
      </div>
      <span className="intro-form__input">
        <p className="intro-form__input-help">
          First thing first, please introduce us to your boss!
        </p>
        <Input
          placeholder="Name of the boss"
          maxLength={15}
          value={bossName.name}
          onChange={(e) => setBossName({ name: e.target.value })}
        />
      </span>
    </section>
  );
};

export default IntroForm;
