import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Testimonial.scss";

const Testimonial = ({ scale, title, user }) => {
    return (
        <span className="testimonail" style={{ transform: `scale(${scale})` }}>
            <div className="testimonail__image-container">
                <img className="testimonail__image" src="https://salisburygreenhouse.com/wp-content/uploads/Header-Benefits-of-Big-Trees.jpg" alt="testimonial" />
            </div>
            <div className="testimonail__details">
                <p className="testimonail__details-title">{ title }</p>
                <p className="testimonail__details-user">{ user }</p>
                <span className="testimonail__details-ratings">
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                    <FontAwesomeIcon icon={faStar} />
                </span>
            </div>
        </span>
    );
}

export default Testimonial;