import Input from "../../Input/Input";
import Logo from "../../../assets/logos/logo.png";

const SuspectName = ({ suspectNumber, state, setSuspect }) => {
  return (
    <span className="intro-form__input">
      <div className="intro-form__header">
        <div
          className="intro-form__logo-container"
          style={{ marginBottom: "20px" }}
        >
          <img className="intro-form__logo" src={Logo} alt="Logo" />
        </div>
      </div>
      <h2 className="suspect__intro-title">
        Enter suspect #{suspectNumber} name
      </h2>
      <Input
        placeholder={`Name of the suspect #${suspectNumber}`}
        maxLength={15}
        value={state.name}
        clearField={() => setSuspect({ ...state, name: "" })}
        onChange={(e) => setSuspect({ ...state, name: e.target.value })}
      />
    </span>
  );
};

export default SuspectName;
