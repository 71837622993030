import "./Welcome.scss";
import Button from "../../components/Button/Button";
import { Link } from "react-router-dom";
import Logo from "../../assets/logos/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import Testimonial from "../../components/Testimonial/Testimonial";
import SocialScavengerLogo from "../../assets/logos/social_scavenger.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Accordion } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

// Logos
import GoogleLogo from "../../assets/clients/google.png";
import BudgeLogo from "../../assets/clients/budge.png";
import BlackStoneLogo from "../../assets/clients/blackstone.png";
import EdcLogo from "../../assets/clients/edc.png";
import MSLogo from "../../assets/clients/morgan_stanley.png";
import OtisLogo from "../../assets/clients/otis.png";
import RBLogo from "../../assets/clients/rb.png";
import RBCLogo from "../../assets/clients/rbc.png";
import SkaddenLogo from "../../assets/clients/skadden.png";
import TwitterLogo from "../../assets/clients/twitter.jpg";

const Welcome = () => {
  return (
    <section className="welcome">
      <div className="welcome__banner">
        <div className="welcome__banner-wrapper">
          <div className="welcome__banner-left">
            <div className="welcome__banner-logo-container">
              <img className="welcome__banner-logo" src={Logo} alt="logo" />
            </div>
            <h1 className="welcome__banner-title">
              A Virtual Escape Style Game - to Save YOUR boss
            </h1>
            <p className="welcome__banner-description">
              Engage your team in the most unique virtual team game you’ll ever
              play, with this custom escape style whodunnit - where your boss
              has been kidnapped!{" "}
            </p>
            <Link to="/create">
              <Button
                text="Configure Your Event"
                type="primary"
                icon={<FontAwesomeIcon icon={faGear} />}
              />
            </Link>
          </div>
          <div className="welcome__banner-right">
            <div className="welcome__banner-image-container">
              <img
                className="welcome__banner-image"
                src="https://d9hhrg4mnvzow.cloudfront.net/signups.socialscavenger.com/virtual-games-who-kidnapped-the-boss/8d00e8eb-banner-graphic2_10j80ef0j80ee000000000.png"
                alt="banner-img"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="welcome__testimonials">
        <div className="welcome__testimonials-wrapper">
          <div className="welcome__testimonials-rating">
            <div className="welcome__testimonials-rating-image-container">
              <img
                className="welcome__testimonials-rating-image"
                src="https://d9hhrg4mnvzow.cloudfront.net/signups.socialscavenger.com/virtual-games-who-kidnapped-the-boss/b5b9aa4c-star3_107806s07406s002000000.png"
                alt="ratings"
              />
            </div>
            <p className="welcome__testimonials-rating-title">
              Player rating: 4.7 out of 5
            </p>
            <p className="welcome__testimonials-rating-description">
              97% rated 4 stars or higher
            </p>
          </div>
          <div className="welcome__testimonials-list">
            <div className="welcome__testimonials-list-group">
              <Testimonial
                scale={0.8}
                title="Exciting and Challenging!  So fun."
                user="Cara"
              />
            </div>
            <div className="welcome__testimonials-list-group">
              <Testimonial
                scale={1}
                title="The was totally awesome!"
                user="Fragomen"
              />
              <Testimonial
                scale={1.2}
                title="Suspenseful - great team activity!"
                user="Coffee Meets Bagel"
              />
              <Testimonial
                scale={0.8}
                title="Would completely recommend this!"
                user="Bump"
              />
            </div>
            <div className="welcome__testimonials-list-group">
              <Testimonial
                scale={1.1}
                title="The answers are sometimes right in front of you!"
                user="PepsiCo"
              />
              <Testimonial
                scale={0.7}
                title="What a cool activity to play with co-workers!"
                user="Health Union"
              />
            </div>
          </div>
          <div className="welcome__testimonials-list-mobile">
            <Testimonial
              scale={0.8}
              title="Exciting and Challenging!  So fun."
              user="Cara"
            />
            <Testimonial
              scale={1}
              title="The was totally awesome!"
              user="Fragomen"
            />
            <Testimonial
              scale={1.2}
              title="Suspenseful - great team activity!"
              user="Coffee Meets Bagel"
            />
            <Testimonial
              scale={0.8}
              title="Would completely recommend this!"
              user="Bump"
            />
            <Testimonial
              scale={1.1}
              title="The answers are sometimes right in front of you!"
              user="PepsiCo"
            />
            <Testimonial
              scale={0.7}
              title="What a cool activity to play with co-workers!"
              user="Health Union"
            />
          </div>
        </div>
      </div>
      <div className="welcome__how-works">
        <div className="welcome__how-works-wrapper">
          <h2>How it works?</h2>
          <div className="welcome__how-works-slider">
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              cssMode={true}
              navigation={true}
              pagination={true}
              mousewheel={true}
              keyboard={true}
              modules={[Navigation, Pagination, Mousewheel, Keyboard]}
              className="welcome__how-works-swiper"
            >
              <SwiperSlide className="welcome__how-works-slide">
                <div className="welcome__how-works-slide-content">
                  <h3>The Game</h3>
                  <p>
                    Your Boss has been kidnapped! 5 members of your organization
                    are on the top of our suspect list. Breaking out into case
                    squads (and heading into breakout rooms) , teams will have
                    50 minutes to work through a series of riddles, puzzles and
                    multimedia challenges to unlock 5 suspect statements. With
                    those statements revealed can your team win the day and save
                    the boss? And can you earn more points than your competition
                    in the process!?
                  </p>
                </div>
                <div className="welcome__how-works-slide-image">
                  <img
                    className="welcome__how-works-slide-image-data"
                    src="https://d9hhrg4mnvzow.cloudfront.net/signups.socialscavenger.com/virtual-games-who-kidnapped-the-boss/d3028610-01-thegame_10ci0c8000000000000000.png"
                    alt="game"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="welcome__how-works-slide">
                <div className="welcome__how-works-slide-content">
                  <h3>Gameday</h3>
                  <p>
                    On game day our MC will take teams through the background
                    story, break down the rules and send teams into breakout
                    rooms to crack the case using the Social Scavenger web
                    software. With only 50 minutes teams will have to decide
                    when to use hints to advance them in the game. The one
                    catch...hints cost valuable points, needed to win the game.
                    Beyond puzzles you’ll be dealing with trivia and media
                    challenges (yes - surprise photo and videos).{" "}
                  </p>
                </div>
                <div className="welcome__how-works-slide-image">
                  <img
                    className="welcome__how-works-slide-image-data"
                    src="https://d9hhrg4mnvzow.cloudfront.net/signups.socialscavenger.com/virtual-games-who-kidnapped-the-boss/8e48615c-02-gameday_10af08t000000000000000.png"
                    alt="game"
                  />
                </div>
              </SwiperSlide>
              <SwiperSlide className="welcome__how-works-slide">
                <div className="welcome__how-works-slide-content">
                  <h3>When the Clock Runs out…</h3>
                  <p>
                    All players will be brought back to our main room where
                    you’ll discover the fun isn’t over yet. Voting on bonus
                    rounds, a walkthrough of your custom challenges, a ‘best of
                    media’, revealing the suspect and the winners - all part of
                    an amazing wrap up that brings the event together - and
                    don't forget your souvenir poster - a virtual time marked in
                    history!
                  </p>
                </div>
                <div className="welcome__how-works-slide-image">
                  <img
                    className="welcome__how-works-slide-image-data"
                    src="https://d9hhrg4mnvzow.cloudfront.net/signups.socialscavenger.com/virtual-games-who-kidnapped-the-boss/d84c2229-03-clock_10dr09q000000000000000.png"
                    alt="game"
                  />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="welcome__how-works-mobile">
            <div className="welcome__how-works-mobile-section">
              <h3>The Game</h3>
              <div className="welcome__how-works-slide-image-mobile">
                <img
                  className="welcome__how-works-slide-image-data-mobile"
                  src="https://d9hhrg4mnvzow.cloudfront.net/signups.socialscavenger.com/virtual-games-who-kidnapped-the-boss/d3028610-01-thegame_10ci0c8000000000000000.png"
                  alt="game"
                />
              </div>
              <div className="welcome__how-works-slide-content">
                <p>
                  Your Boss has been kidnapped! 5 members of your organization
                  are on the top of our suspect list. Breaking out into case
                  squads (and heading into breakout rooms) , teams will have 50
                  minutes to work through a series of riddles, puzzles and
                  multimedia challenges to unlock 5 suspect statements. With
                  those statements revealed can your team win the day and save
                  the boss? And can you earn more points than your competition
                  in the process!?
                </p>
              </div>
            </div>
            <div className="welcome__how-works-mobile-section">
              <h3>Gameday</h3>
              <div className="welcome__how-works-slide-image-mobile">
                <img
                  className="welcome__how-works-slide-image-data-mobile"
                  src="https://d9hhrg4mnvzow.cloudfront.net/signups.socialscavenger.com/virtual-games-who-kidnapped-the-boss/8e48615c-02-gameday_10af08t000000000000000.png"
                  alt="game"
                />
              </div>
              <div className="welcome__how-works-slide-content">
                <p>
                  On game day our MC will take teams through the background
                  story, break down the rules and send teams into breakout rooms
                  to crack the case using the Social Scavenger web software.
                  With only 50 minutes teams will have to decide when to use
                  hints to advance them in the game. The one catch...hints cost
                  valuable points, needed to win the game. Beyond puzzles you’ll
                  be dealing with trivia and media challenges (yes - surprise
                  photo and videos).{" "}
                </p>
              </div>
            </div>
            <div className="welcome__how-works-mobile-section">
              <h3>When the Clock Runs out…</h3>
              <div className="welcome__how-works-slide-image-mobile">
                <img
                  className="welcome__how-works-slide-image-data-mobile"
                  src="https://d9hhrg4mnvzow.cloudfront.net/signups.socialscavenger.com/virtual-games-who-kidnapped-the-boss/d84c2229-03-clock_10dr09q000000000000000.png"
                  alt="game"
                />
              </div>
              <div className="welcome__how-works-slide-content">
                <p>
                  All players will be brought back to our main room where you’ll
                  discover the fun isn’t over yet. Voting on bonus rounds, a
                  walkthrough of your custom challenges, a ‘best of media’,
                  revealing the suspect and the winners - all part of an amazing
                  wrap up that brings the event together - and don't forget your
                  souvenir poster - a virtual time marked in history!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="welcome__faq">
        <div className="welcome__faq-wrapper">
          <h2 className="welcome__faq-title">Frequently Asked Questions</h2>
          <p className="welcome__faq-helper">
            Have more questions? Contact Us →
          </p>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Can the boss and suspects play?
              </Accordion.Header>
              <Accordion.Body>
                Absolutely! The suspects won’t know who’s done it - and the Boss
                - well we have fun ways to move them around the game so they get
                to play with every team!
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Should I play the 60 or 90 minute version?
              </Accordion.Header>
              <Accordion.Body>
                You'll have a great time no matter what but nobody (so far)
                who's played the 90 minutes has said "we wish we had less time".
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Can event organizers play?</Accordion.Header>
              <Accordion.Body>
                100% you can play! Our hosts and facilitators take care of
                everything on game day so you are free to join the game as a
                player and have just as much fun as the team.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>What about prizes?</Accordion.Header>
              <Accordion.Body>
                95% of teams do not give away anything other than bragging
                rights! This game can get competitive so winning will be prize
                enough. If you really want to give away prizes talk to your game
                producer and we’ll work it into the script and award show.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                How much work is it for me (the one booking this)?
              </Accordion.Header>
              <Accordion.Body>
                You can get it up and running within minutes!
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      <div className="welcome__logos">
        <div className="welcome__logos-wrapper">
          <h2 className="welcome__logos-title">Clients We Helped Virtually</h2>
          <div className="welcome__logos-list">
            <div className="welcome__logos-logo">
              <img
                className="welcome__logos-logo-image"
                src={GoogleLogo}
                alt="client-logo"
              />
            </div>
            <div className="welcome__logos-logo">
              <img
                className="welcome__logos-logo-image"
                src={BudgeLogo}
                alt="client-logo"
              />
            </div>
            <div className="welcome__logos-logo">
              <img
                className="welcome__logos-logo-image"
                src={BlackStoneLogo}
                alt="client-logo"
              />
            </div>
            <div className="welcome__logos-logo">
              <img
                className="welcome__logos-logo-image"
                src={MSLogo}
                alt="client-logo"
              />
            </div>
            <div className="welcome__logos-logo">
              <img
                className="welcome__logos-logo-image"
                src={OtisLogo}
                alt="client-logo"
              />
            </div>
            <div className="welcome__logos-logo">
              <img
                className="welcome__logos-logo-image"
                src={RBLogo}
                alt="client-logo"
              />
            </div>
            <div className="welcome__logos-logo">
              <img
                className="welcome__logos-logo-image"
                src={RBCLogo}
                alt="client-logo"
              />
            </div>
            <div className="welcome__logos-logo">
              <img
                className="welcome__logos-logo-image"
                src={SkaddenLogo}
                alt="client-logo"
              />
            </div>
            <div className="welcome__logos-logo">
              <img
                className="welcome__logos-logo-image"
                src={TwitterLogo}
                alt="client-logo"
              />
            </div>
            <div className="welcome__logos-logo">
              <img
                className="welcome__logos-logo-image"
                src={EdcLogo}
                alt="client-logo"
              />
            </div>
          </div>
        </div>
      </div>
      <footer className="welcome__footer">
        <div className="welcome__footer-wrapper">
          <div className="welcome__footer-logo-container">
            <img
              className="welcome__footer-logo"
              src={SocialScavengerLogo}
              alt="logo"
            />
          </div>
          <div className="welcome__footer-copyright">
            Social Scavenger Inc. © {new Date().getFullYear()}
          </div>
        </div>
      </footer>
    </section>
  );
};

export default Welcome;
