import "./Input.scss";

const Input = ({
  type,
  placeholder,
  style,
  style2,
  className,
  id,
  required,
  lable,
  value,
  onChange,
  maxLength,
  clearField,
  onBlur,
}) => {
  return (
    <div className="input-group" style={style2}>
      <input
        className={`input ${className}`}
        id={id}
        type={type}
        placeholder={placeholder}
        style={style}
        value={value}
        onChange={onChange}
        required={required}
        maxLength={maxLength}
        onBlur={onBlur}
      />
      {lable ? <lable className="input__lable">{lable}</lable> : ""}
      <span className="input__clear" onClick={clearField}>
        Clear
      </span>
    </div>
  );
};

export default Input;
