import { useEffect, useState } from "react";
import "./FormSteps.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import PurchaseTeam from "../PurchaseTeam/PurchaseTeam";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import IntroForm from "../IntroForm/IntroForm";
import Button from "../Button/Button";
import SuspectName from "../Steps/SuspectName/SuspectName";
import SuspectTruthOrLie from "../Steps/SuspectTruthOrLie/SuspectTruthOrLie";
import SuspectMotive from "../Steps/SuspectMotive/SuspectMotive";
import SuspectImage from "../Steps/SuspectImage/SuspectImage";
import BossImage from "../Steps/BossImage/BossImage";

const FormSteps = () => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  const [controlVisible, setControlVisible] = useState(true);

  let savedData = {
    suspect1: null,
    suspect2: null,
    suspect3: null,
    suspect4: null,
    suspect5: null,
  };

  if (localStorage.getItem("suspect1")) {
    savedData.suspect1 = JSON.parse(localStorage.getItem("suspect1"));
  }
  if (localStorage.getItem("suspect2")) {
    savedData.suspect2 = JSON.parse(localStorage.getItem("suspect2"));
  }
  if (localStorage.getItem("suspect3")) {
    savedData.suspect3 = JSON.parse(localStorage.getItem("suspect3"));
  }
  if (localStorage.getItem("suspect4")) {
    savedData.suspect4 = JSON.parse(localStorage.getItem("suspect4"));
  }
  if (localStorage.getItem("suspect5")) {
    savedData.suspect5 = JSON.parse(localStorage.getItem("suspect5"));
  }

  const [bossName, setBossName] = useState(
    localStorage.getItem("bossName")
      ? JSON.parse(localStorage.getItem("bossName"))
      : {
          name: null,
        }
  );

  const [bossImages, setBossImages] = useState({
    hostage: [
      {
        data_url:
          "https://s3.amazonaws.com/froala-production/uploads1653499219367-Boss+Peter+White+Kidnapped+Photo.jpeg",
      },
    ],
    free: [
      {
        data_url:
          "https://ss-assets.socialscavenger.com/images/111316d0be7d013ac4657e563599913a.jpeg",
      },
    ],
  });

  const [suspect1, setsuspect1] = useState(
    localStorage.getItem("suspect1")
      ? {
          name: savedData.suspect1.name,
          truth_1: savedData.suspect1.truth_1,
          truth_2: savedData.suspect1.truth_2,
          lie: savedData.suspect1.lie,
          motive: savedData.suspect1.motive,
          image: [{ data_url: savedData.suspect1.image[0].data_url }],
        }
      : {
          name: null,
          truth_1: null,
          truth_2: null,
          lie: null,
          motive: null,
          image: [
            {
              data_url:
                "https://ss-assets.socialscavenger.com/images/5750eea0c3e2013a033842fb66c6c427.jpg",
            },
          ],
        }
  );

  const [suspect2, setsuspect2] = useState(
    localStorage.getItem("suspect2")
      ? {
          name: savedData.suspect2.name,
          truth_1: savedData.suspect2.truth_1,
          truth_2: savedData.suspect2.truth_2,
          lie: savedData.suspect2.lie,
          motive: savedData.suspect2.motive,
          image: [{ data_url: savedData.suspect2.image[0].data_url }],
        }
      : {
          name: null,
          truth_1: null,
          truth_2: null,
          lie: null,
          motive: null,
          image: [
            {
              data_url:
                "https://ss-assets.socialscavenger.com/images/4e71a000c3e2013abf4f3ed001e7a88b.jpg",
            },
          ],
        }
  );

  const [suspect3, setsuspect3] = useState(
    localStorage.getItem("suspect3")
      ? {
          name: savedData.suspect3.name,
          truth_1: savedData.suspect3.truth_1,
          truth_2: savedData.suspect3.truth_2,
          lie: savedData.suspect3.lie,
          motive: savedData.suspect3.motive,
          image: [{ data_url: savedData.suspect3.image[0].data_url }],
        }
      : {
          name: null,
          truth_1: null,
          truth_2: null,
          lie: null,
          motive: null,
          image: [
            {
              data_url:
                "https://ss-assets.socialscavenger.com/images/5f667230c3e2013a8d363e412b667f39.jpeg",
            },
          ],
        }
  );

  const [suspect4, setsuspect4] = useState(
    localStorage.getItem("suspect4")
      ? {
          name: savedData.suspect4.name,
          truth_1: savedData.suspect4.truth_1,
          truth_2: savedData.suspect4.truth_2,
          lie: savedData.suspect4.lie,
          motive: savedData.suspect4.motive,
          image: [{ data_url: savedData.suspect4.image[0].data_url }],
        }
      : {
          name: null,
          truth_1: null,
          truth_2: null,
          lie: null,
          motive: null,
          image: [
            {
              data_url:
                "https://ss-assets.socialscavenger.com/images/655f2630c3e2013ad70e6ac114a5449c.jpg",
            },
          ],
        }
  );

  const [suspect5, setsuspect5] = useState(
    localStorage.getItem("suspect5")
      ? {
          name: savedData.suspect5.name,
          truth_1: savedData.suspect5.truth_1,
          truth_2: savedData.suspect5.truth_2,
          lie: savedData.suspect5.lie,
          motive: savedData.suspect5.motive,
          image: [{ data_url: savedData.suspect5.image[0].data_url }],
        }
      : {
          name: null,
          truth_1: null,
          truth_2: null,
          lie: null,
          motive: null,
          image: [
            {
              data_url:
                "https://ss-assets.socialscavenger.com/images/6d195720c3e2013a1a056efe6c077d16.jpg",
            },
          ],
        }
  );

  const config = {
    steps: [
      {
        state: bossName.name,
        component: <IntroForm bossName={bossName} setBossName={setBossName} />,
      },
      {
        state: suspect1.name,
        component: (
          <SuspectName
            suspectNumber={1}
            state={suspect1}
            setSuspect={setsuspect1}
          />
        ),
      },
      {
        state: suspect1.truth_1,
        component: (
          <SuspectTruthOrLie
            suspectName={suspect1.name}
            tol="truth"
            number={1}
            state={suspect1}
            setSuspect={setsuspect1}
            objectKey={"truth_1"}
          />
        ),
      },
      {
        state: suspect1.truth_2,
        component: (
          <SuspectTruthOrLie
            suspectName={suspect1.name}
            tol="truth"
            number={2}
            state={suspect1}
            setSuspect={setsuspect1}
            objectKey={"truth_2"}
          />
        ),
      },
      {
        state: suspect1.lie,
        component: (
          <SuspectTruthOrLie
            suspectName={suspect1.name}
            tol="lie"
            state={suspect1}
            setSuspect={setsuspect1}
            objectKey={"lie"}
          />
        ),
      },
      {
        state: suspect1.motive,
        component: (
          <SuspectMotive
            suspectName={suspect1.name}
            state={suspect1}
            setSuspect={setsuspect1}
          />
        ),
      },
      {
        state: suspect1.image,
        isImage: true,
        component: (
          <SuspectImage
            suspectName={suspect1.name}
            state={suspect1}
            setSuspect={setsuspect1}
            number={1}
          />
        ),
      },
      {
        state: suspect2.name,
        component: (
          <SuspectName
            suspectNumber={2}
            state={suspect2}
            setSuspect={setsuspect2}
          />
        ),
      },
      {
        state: suspect2.truth_1,
        component: (
          <SuspectTruthOrLie
            suspectName={suspect2.name}
            tol="truth"
            number={1}
            state={suspect2}
            setSuspect={setsuspect2}
            objectKey={"truth_1"}
          />
        ),
      },
      {
        state: suspect2.truth_2,
        component: (
          <SuspectTruthOrLie
            suspectName={suspect2.name}
            tol="truth"
            number={2}
            state={suspect2}
            setSuspect={setsuspect2}
            objectKey={"truth_2"}
          />
        ),
      },
      {
        state: suspect2.lie,
        component: (
          <SuspectTruthOrLie
            suspectName={suspect2.name}
            tol="lie"
            state={suspect2}
            setSuspect={setsuspect2}
            objectKey={"lie"}
          />
        ),
      },
      {
        state: suspect2.motive,
        component: (
          <SuspectMotive
            suspectName={suspect2.name}
            state={suspect2}
            setSuspect={setsuspect2}
          />
        ),
      },
      {
        state: suspect2.image,
        isImage: true,
        component: (
          <SuspectImage
            suspectName={suspect2.name}
            state={suspect2}
            setSuspect={setsuspect2}
            number={2}
          />
        ),
      },
      {
        state: suspect3.name,
        component: (
          <SuspectName
            suspectNumber={3}
            state={suspect3}
            setSuspect={setsuspect3}
          />
        ),
      },
      {
        state: suspect3.truth_1,
        component: (
          <SuspectTruthOrLie
            suspectName={suspect3.name}
            tol="truth"
            number={1}
            state={suspect3}
            setSuspect={setsuspect3}
            objectKey={"truth_1"}
          />
        ),
      },
      {
        state: suspect3.truth_2,
        component: (
          <SuspectTruthOrLie
            suspectName={suspect3.name}
            tol="truth"
            number={2}
            state={suspect3}
            setSuspect={setsuspect3}
            objectKey={"truth_2"}
          />
        ),
      },
      {
        state: suspect3.lie,
        component: (
          <SuspectTruthOrLie
            suspectName={suspect3.name}
            tol="lie"
            state={suspect3}
            setSuspect={setsuspect3}
            objectKey={"lie"}
          />
        ),
      },
      {
        state: suspect3.motive,
        component: (
          <SuspectMotive
            suspectName={suspect3.name}
            state={suspect3}
            setSuspect={setsuspect3}
          />
        ),
      },
      {
        state: suspect3.image,
        isImage: true,
        component: (
          <SuspectImage
            suspectName={suspect3.name}
            state={suspect3}
            setSuspect={setsuspect3}
            number={3}
          />
        ),
      },
      {
        state: suspect4.name,
        component: (
          <SuspectName
            suspectNumber={4}
            state={suspect4}
            setSuspect={setsuspect4}
          />
        ),
      },
      {
        state: suspect4.truth_1,
        component: (
          <SuspectTruthOrLie
            suspectName={suspect4.name}
            tol="truth"
            number={1}
            state={suspect4}
            setSuspect={setsuspect4}
            objectKey={"truth_1"}
          />
        ),
      },
      {
        state: suspect4.truth_2,
        component: (
          <SuspectTruthOrLie
            suspectName={suspect4.name}
            tol="truth"
            number={2}
            state={suspect4}
            setSuspect={setsuspect4}
            objectKey={"truth_2"}
          />
        ),
      },
      {
        state: suspect4.lie,
        component: (
          <SuspectTruthOrLie
            suspectName={suspect4.name}
            tol="lie"
            state={suspect4}
            setSuspect={setsuspect4}
            objectKey={"lie"}
          />
        ),
      },
      {
        state: suspect4.motive,
        component: (
          <SuspectMotive
            suspectName={suspect4.name}
            state={suspect4}
            setSuspect={setsuspect4}
          />
        ),
      },
      {
        state: suspect4.image,
        isImage: true,
        component: (
          <SuspectImage
            suspectName={suspect4.name}
            state={suspect4}
            setSuspect={setsuspect4}
            number={4}
          />
        ),
      },
      {
        state: suspect5.name,
        component: (
          <SuspectName
            suspectNumber={5}
            state={suspect5}
            setSuspect={setsuspect5}
          />
        ),
      },
      {
        state: suspect5.truth_1,
        component: (
          <SuspectTruthOrLie
            suspectName={suspect5.name}
            tol="truth"
            number={1}
            state={suspect5}
            setSuspect={setsuspect5}
            objectKey={"truth_1"}
          />
        ),
      },
      {
        state: suspect5.truth_2,
        component: (
          <SuspectTruthOrLie
            suspectName={suspect5.name}
            tol="truth"
            number={2}
            state={suspect5}
            setSuspect={setsuspect5}
            objectKey={"truth_2"}
          />
        ),
      },
      {
        state: suspect5.lie,
        component: (
          <SuspectTruthOrLie
            suspectName={suspect5.name}
            tol="lie"
            state={suspect5}
            setSuspect={setsuspect5}
            objectKey={"lie"}
          />
        ),
      },
      {
        state: suspect5.motive,
        component: (
          <SuspectMotive
            suspectName={suspect5.name}
            state={suspect5}
            setSuspect={setsuspect5}
          />
        ),
      },
      {
        state: suspect5.image,
        isImage: true,
        component: (
          <SuspectImage
            suspectName={suspect5.name}
            state={suspect5}
            setSuspect={setsuspect5}
            number={5}
          />
        ),
      },
      {
        state: bossImages.hostage,
        isImage: true,
        component: (
          <BossImage
            state={bossImages}
            src={bossImages.hostage}
            type="hostage"
            setBossImages={setBossImages}
          />
        ),
      },
      {
        state: bossImages.free,
        isImage: true,
        component: (
          <BossImage
            state={bossImages}
            src={bossImages.free}
            type="rescued"
            setBossImages={setBossImages}
          />
        ),
      },
      {
        state: [],
        component: (
          <Elements stripe={stripePromise}>
            <PurchaseTeam
              bossName={bossName}
              bossImages={bossImages}
              suspect1={suspect1}
              suspect2={suspect2}
              suspect3={suspect3}
              suspect4={suspect4}
              suspect5={suspect5}
              setControlVisible={setControlVisible}
            />
          </Elements>
        ),
      },
    ],
  };

  useEffect(() => {
    localStorage.setItem("bossName", JSON.stringify(bossName));
  }, [bossName]);

  useEffect(() => {
    localStorage.setItem("bossImages", JSON.stringify(bossImages));
  }, [bossImages]);

  useEffect(() => {
    localStorage.setItem("suspect1", JSON.stringify(suspect1));
  }, [suspect1]);

  useEffect(() => {
    localStorage.setItem("suspect2", JSON.stringify(suspect2));
  }, [suspect2]);

  useEffect(() => {
    localStorage.setItem("suspect3", JSON.stringify(suspect3));
  }, [suspect3]);

  useEffect(() => {
    localStorage.setItem("suspect4", JSON.stringify(suspect4));
  }, [suspect4]);

  useEffect(() => {
    localStorage.setItem("suspect5", JSON.stringify(suspect5));
  }, [suspect5]);

  const [index, setIndex] = useState(
    localStorage.getItem("current-page")
      ? parseInt(localStorage.getItem("current-page"))
      : 0
  );

  useEffect(() => {
    localStorage.setItem("current-page", index);
  }, [index]);

  const goNext = () => {
    const isEmpty =
      config.steps[index].state === null || config.steps[index].state === "";

    if (!isEmpty) {
      if (config.steps[index] && config.steps[index].isImage) {
        if (
          config.steps[index].state[0].data_url.split("/")[2] !==
          "ss-assets.socialscavenger.com"
        ) {
          setIndex(index + 1);
          window.scrollTo(0, 0);
        } else {
          toast.error("Make sure to replace the image!");
        }
      } else {
        setIndex(index + 1);
        window.scrollTo(0, 0);
      }
    } else {
      toast.error("Make sure all the fields are filled before moving forward!");
    }
  };

  return (
    <>
      <div className="form-steps">
        <div>
          {config.steps.map((step, i) => {
            return (
              <div style={{ display: index === i ? "block" : "none" }}>
                {step.component}
              </div>
            );
          })}
        </div>
        <p
          className="form-steps__counter"
          style={{ display: controlVisible ? "block" : "none" }}
        >
          Step {index + 1} out of {config.steps.length}
        </p>
        <div
          className="form-steps__controller"
          style={{ display: controlVisible ? "block" : "none" }}
        >
          <div className="form-steps__controller-wrapper">
            <span
              className="form-steps__controller-button"
              style={{ visibility: index === 0 ? "hidden" : "visible" }}
              onClick={() => {
                setIndex(index - 1);
                window.scrollTo(0, 0);
              }}
            >
              <Button
                text="Previous"
                style={{ padding: "5.5px 30px 7px 60px" }}
                icon_style={{ width: "38px" }}
                icon_side="left"
                type="primary"
                fn={() => {
                  setIndex(index - 1);
                  window.scrollTo(0, 0);
                }}
                icon={<FontAwesomeIcon icon={faArrowLeft} />}
              />
            </span>
            <span
              className="form-steps__controller-button"
              style={{
                visibility:
                  index === config.steps.length - 1 ? "hidden" : "visible",
              }}
              onClick={() => goNext()}
            >
              <Button
                text="Next"
                style={{ padding: "5.5px 60px 7px 30px" }}
                icon_style={{ width: "38px" }}
                type="primary"
                fn={() => goNext()}
                icon={<FontAwesomeIcon icon={faArrowRight} />}
              />
            </span>
          </div>
        </div>
      </div>
      <ToastContainer limit={1} autoClose={1500} />
    </>
  );
};

export default FormSteps;
