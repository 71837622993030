import Input from "../../Input/Input";
import Logo from "../../../assets/logos/logo.png";

const SuspectTruthOrLie = ({
  suspectName,
  tol,
  number,
  state,
  setSuspect,
  objectKey,
}) => {
  const Example = (objectKey) => {
    return (
      <div className="example">
        <div className="example__body">
          <span className="example__type">Example</span>
          {objectKey.objectKey === "truth_1" ? (
            <span>
              I play some sports left-handed and some sports right-handed
            </span>
          ) : (
            ""
          )}
          {objectKey.objectKey === "truth_2" ? (
            <span>I read thrillers for fun</span>
          ) : (
            ""
          )}
          {objectKey.objectKey === "lie" ? (
            <span>I attended six schools as an army brat</span>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  return (
    <span className="intro-form__input">
      <div className="intro-form__header">
        <div
          className="intro-form__logo-container"
          style={{ marginBottom: "20px" }}
        >
          <img className="intro-form__logo" src={Logo} alt="Logo" />
        </div>
      </div>
      <h2 className="suspect__intro-title">
        Enter {suspectName}'s {tol} {number ? "#" + number : ""}
      </h2>
      {<Example objectKey={objectKey} />}
      {objectKey === "truth_1" ? (
        <Input
          placeholder={`${suspectName}'s truth #1`}
          maxLength={15}
          value={state.truth_1}
          clearField={() => setSuspect({ ...state, truth_1: "" })}
          onChange={(e) => setSuspect({ ...state, truth_1: e.target.value })}
        />
      ) : (
        ""
      )}
      {objectKey === "truth_2" ? (
        <Input
          placeholder={`${suspectName}'s truth #2`}
          maxLength={15}
          value={state.truth_2}
          clearField={() => setSuspect({ ...state, truth_2: "" })}
          onChange={(e) => setSuspect({ ...state, truth_2: e.target.value })}
        />
      ) : (
        ""
      )}
      {objectKey === "lie" ? (
        <Input
          placeholder={`${suspectName}'s lie`}
          maxLength={15}
          value={state.lie}
          clearField={() => setSuspect({ ...state, lie: "" })}
          onChange={(e) => setSuspect({ ...state, lie: e.target.value })}
        />
      ) : (
        ""
      )}
    </span>
  );
};

export default SuspectTruthOrLie;
