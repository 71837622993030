import "./BossImage.scss";
import ImageUploading from "react-images-uploading";
import Resizer from "react-image-file-resizer";
import Logo from "../../../assets/logos/logo.png";

const BossImage = ({ state, setBossImages, type, src }) => {
  const resizeFile = (file) => {
    try {
      Resizer.imageFileResizer(
        file[0].file,
        500,
        500,
        "JPEG",
        50,
        0,
        (uri) => {
          const imgData = file;
          imgData[0].data_url = uri;
          setBossImages(
            type === "hostage"
              ? { ...state, hostage: imgData }
              : { ...state, free: imgData }
          );
        },
        "base64",
        500,
        500
      );
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <span className="intro-form__input">
      <div className="intro-form__header">
        <div
          className="intro-form__logo-container"
          style={{ marginBottom: "20px" }}
        >
          <img className="intro-form__logo" src={Logo} alt="Logo" />
        </div>
      </div>
      <h2 className="suspect__intro-title">Upload boss's {type} image</h2>
      {type === "hostage" ? (
        <p>
          An image of your boss in a hostage situation. This image will be used
          start of the game to let the players know your boss has been captured.
        </p>
      ) : (
        <p>
          This image of your boss will be used once you win the game as a
          reward!
        </p>
      )}
      <div className="example">
        <div className="example__body">
          <span className="example__type example__type--2">Photo Guide</span>
          <ul>
            <li>Upload highest quality image</li>
            <li>Make sure the image isn't streched</li>
            <li>Preffered image size is 1:1</li>
          </ul>
        </div>
      </div>
      <div className="suspect-image">
        <div className="example__image-container suspect-image-data boss-image">
          <div
            className="example__image-container-photo"
            style={{ height: "100%", width: "100%" }}
          >
            <img
              className="example__image"
              src={src && src[0].data_url}
              alt={`boss-${type}`}
            />
          </div>
        </div>
        <ImageUploading
          multiple={false}
          value={src}
          onChange={(e) => {
            resizeFile(e);
          }}
          maxNumber={3423}
          dataURLKey="data_url"
        >
          {({ onImageUpload, isDragging, dragProps }) => (
            <div
              className="suspect-component__uploader suspect-image-uploader"
              style={
                isDragging
                  ? { color: "#21DE93", borderColor: "#21DE93" }
                  : undefined
              }
              onClick={onImageUpload}
              {...dragProps}
            >
              <p className="suspect-component__uploader-title">
                Add your boss's {type} image or tap to upload
              </p>
            </div>
          )}
        </ImageUploading>
      </div>
    </span>
  );
};

export default BossImage;
