import "./Button.scss";

const Button = ({ text, fn, type, style, icon_style, icon, icon_side, button_type, disabled }) => {
    return (
        <button className={`button button--${type} button--${icon_side}`} style={style} onClick={fn} type={button_type} disabled={disabled}>
            {text}
            <span style={icon_style} className={icon_side === "left" ? "button-icon-left" : "button-icon"}>{icon}</span>
        </button>
    );
}

export default Button;